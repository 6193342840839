<template>
  <article class="enablebanking-terms">
    <h1>{{ $t('Terms of Service') }}</h1>
    <p>{{ $t('Last updated:') }} {{ new Date("2024-03-08").toLocaleDateString($i18n.locale, { year: 'numeric', month: 'long', day: 'numeric' }) }}</p>
    <p>
      {{ $t('Please read these terms of service carefully before using Enable Banking API.') }}
    </p>
    <h2>{{ $t('Description of the service') }}</h2>
    <p>
      {{ $t('Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.') }}
      {{ $t('To use your payment account data, you must authenticate in the ASPSP service.') }}
      {{ $t('The service was formerly known as Tilisy business API.') }}
    </p>
    <p>
      {{ $t('Your explicit consent to use the payment account data will be maintained in Enable Banking API.') }}
      {{ $t('This service registers your PSU authentication tokens and consent ID.') }}
      {{ $t('Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.') }}
      {{ $t('Your payment account data flows through Enable Banking API and won\'t be registered there.') }}
    </p>
    <p>
      {{ $t('Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.') }}
      {{ $t('The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).') }}
      <i18n tag="span" path="The registration details can be found from {0}."><a href="https://www.finanssivalvonta.fi/en/registers/supervised-entities/" target="_blank">{{ $t('the FIN-FSA register for payment services providers') }}</a></i18n>
    </p>
    <h2>{{ $t('Processing of personal data') }}</h2>
    <p>
      {{ $t('Enable Banking processes personal data only for the purpose and to the extent that Enable Banking API service can function for you.') }}
      {{ $t('When processing personal data Enable Banking API undertakes to comply with the legislation relating to processing of personal data and data protection as well as other legislation applicable and especially the EU General Data Protection Regulation (EU 2016/679).') }}
      {{ $t('The more detailed contents of the personal data processing, including:') }}
      {{ $t('(a) the nature and purpose of the processing;') }}
      {{ $t('(b) the type of personal data and categories of data subjects;') }}
      {{ $t('(c) the applicable data protection measures;') }}
      {{ $t('(d) the customer’s rights and responsibilities;') }}
      {{ $t('and (e) the object and duration of the personal data processing shall be described in greater detail in the Privacy Policy.') }}
    </p>
    <h2>{{ $t('Links to other websites') }}</h2>
    <p>
      {{ $t('Enable Banking may contain links or redirect you to third-party web sites or services that are not owned or controlled by the Company, including but not limited to ASPSPs’ web sites or services.') }}
      {{ $t('The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.') }}
      {{ $t('You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.') }}
      {{ $t('We strongly advise you to read terms and conditions and privacy policies of any third-party web sites or services that you visit.') }}
    </p>
    <h2>{{ $t('No warranty') }}</h2>
    <p>
      {{ $t('Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied:') }}
      {{ $t('(i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon;') }}
      {{ $t('(ii) that the Service will be uninterrupted or error-free;') }}
      {{ $t('(iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.') }}
      {{ $t('Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.') }}
      {{ $t('But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.') }}
    </p>
    <h2>{{ $t('Changes') }}</h2>
    <p>
      {{ $t('The Company reserves the right to modify or replace the Service at any time.') }}
      {{ $t('If a revision is material the Company will make reasonable efforts to notify you prior to any new terms taking effect together with other digital service providers.') }}
      {{ $t('You can also check any updates from this page.') }}
      {{ $t('By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms.') }}
      {{ $t('If you do not agree to the new terms, in whole or in part, please stop using the Service.') }}
    </p>
    <h2>{{ $t('Termination') }}</h2>
    <p>
      <i18n tag="span" path="If for any reason, you no longer wish to use the Service to share your payment accounts data with other digital services, please {0}.">
        <a href="https://enablebanking.com/data-sharing-consents/" target="_blank">{{ $t('visit the Data Sharing Consents page') }}</a>
      </i18n>
      {{ $t('Upon termination, your right to use the Service will cease immediately.') }}
      {{ $t('We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever.') }}
    </p>
    <h2>{{ $t('Governing law') }}</h2>
    <p>
      {{ $t('The laws of Finland shall govern your use of the Service.') }}
      {{ $t('If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.') }}
    </p>
    <h2>{{ $t('Disputes resolution') }}</h2>
    <p>
      {{ $t('If you have any concern or dispute about the Service, at first, you are asked for trying to resolve the dispute informally by contacting the Company.') }}
    </p>
    <p>
      {{ $t('The consumer users in Finland are also entitled to be in contact with Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, kril@oikeus.fi.') }}
    </p>
    <p>
      {{ $t('The company related issues shall be directed to the supervising authority.') }}
      {{ $t('The FIN-FSA contact information is: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (switchboard); www.finanssivalvonta.fi.') }}
    </p>
    <h2>{{ $t('Contact us') }}</h2>
    <i18n tag="p" path="If you have any questions about this terms of service, you can contact us by email at {0}.">
      <a href="mailto:support@enablebanking.com">support@enablebanking.com</a>
    </i18n>
  </article>
</template>
