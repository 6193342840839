import axios from 'axios'
import config from '../config'

const api = axios.create({
  baseURL: config.baseApiUrl
})

export const setBaseURL = (url) => {
  api.defaults.baseURL = url
  api.defaults.withCredentials = true
}

export default api
