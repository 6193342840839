export default{
  // General
  'About {appName}': 'Teave rakenduse {appName} kohta',
  'About Enable Banking API': 'Teave rakenduse Enable Banking API kohta',
  'Application description is not provided.': 'Rakenduse kirjeldust ei esitata.',
  'Authenticate': 'Autentimine',
  'Authentication method': 'Autentimismeetod',
  'Authentication session has expired': 'Autentimisseanss on aegunud',
  'Cancel': 'Tühistamine',
  'Click {0} button if you have confirmed authorization in the BankID app.': 'Kui olete BankID-i rakenduses volituse kinnitanud, klõpsake nupul {0}.',
  'Continue authentication': 'Jätkake autentimist',
  'Enter your OTP code with index {0}': 'Sisestage oma OTP-kood koos indeksiga {0}',
  'Error during authentication:': 'Viga autentimisel:',
  'Error while updating authentication status': 'Autentimise oleku värskendamisel tekkis viga',
  'Open the BankID app on this device': 'Avage selles seadmes rakendus BankID',
  'OTP code': 'OTP kood',
  'Redirecting to your account servicing provider': 'Suunamine teie konto teenusepakkuja juurde',
  'Returning to the authentication initiator': 'Naasmine autentimise algataja juurde',
  'Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page': 'Käivitage oma mobiilseadmes BankID rakendus ja vajutage sellel lehel oleva QR-koodi skannimiseks rakenduses nuppu QR-kood',
  'or': 'või',
  'Starting authorization': 'Autoriseerimise alustamine',
  'Start typing for filtering the list': 'Alustage loendi filtreerimiseks tippimist',
  'To continue please use your authentication app': 'Jätkamiseks kasutage oma autentimisrakendust',
  'Waiting for completion of authentication': 'Autentimise lõpuleviimise ootel',
  'Try again': 'Proovige uuesti',
  'Submit': 'Esita',
  'Unable to access server': 'Serverile puudub juurdepääs',
  'Unable to authenticate': 'Autentimine ei õnnestu',
  'Unable to continue authorization': 'Autoriseerimist ei saa jätkata',
  'Unable to continue decoupled authorization': 'Seotud autoriseerimist ei saa jätkata',
  'Unable to handle authentication status "{status}"': 'Autentimise olekut "{status}" ei saa käsitleda',
  'Unknown error occurred': 'Ilmnes tundmatu viga',
  'You need to close this window': 'Peate selle akna sulgema',
  // Consent
  'Authentication is initiated by {0}.': 'Autentimise algatas {0}.',
  'After you complete authentication, {0} will be shared with the authentication initiator.': 'Pärast autentimise lõpetamist jagatakse {0} autentimise algatajaga.',
  'your payment accounts data': 'teie maksekontode andmeid',
  'the list of your payment accounts': 'teie maksekontode loendit',
  'the list of your payment accounts and accounts balances': 'teie maksekontode loendit ja kontosaldode',
  'the list of your payment accounts and accounts transactions': 'teie maksekontode loendit ja kontotehinguid',
  'the list of your payment accounts, accounts balances and transactions': 'teie maksekontode loendit, kontosaldode ja kontotehinguid',
  'By pressing {0} button you agree with the {1}.': 'Nuppu {0} vajutades nõustute järgmisega: {1}',
  'terms of Enable Banking API service': 'Enable Banking API teenuse tingimused',
  'Press {0} button if you are not willing to share your payment accounts data.': 'Kui te pole nõus maksekontode andmeid jagama, vajutage nuppu {0}.',
  // Payment consent
  'Payment details': 'Makse andmed',
  'Payment ID provided by the application: {0}': 'Rakenduse antud makse ID: {0}',
  'Account number where to transfer funds: {0}': 'Konto number, kuhu raha kanda: {0}',
  'Payee name in the payment: {0}': 'Makse saaja nimi makses: {0}',
  'Payee country: {0}': 'Maksesaaja riik: {0}',
  'Payment amount: {0}': 'Makse summa: {0}',
  'Message or reference number in the payment: {0}': 'Sõnum või viitenumber makses: {0}',
  'Payment is initiated by {0}.': 'Makse algatas {0}.',
  'After you complete authentication, your payment consent will be requested by your account servicing payment service provider.': 'Pärast autentimise lõpetamist küsib teie kontot teenindav makseteenuse pakkuja teie makse nõusolekut.',
  'terms of service': 'kasutustingimused',
  'Press {0} button if you are not willing to complete this payment.': 'Kui te ei soovi seda makset sooritada, vajutage nuppu {0}.',
  'Select Payment Account': 'Valige maksekonto',
  'Confirm': 'Kinnita',
  // About Tilisy
  'Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.': 'Enable Banking API on võrguteenus, mis võimaldab teil kasutada oma muudes digiteenustes valitud panga või sarnase organisatsiooni (Account Servicing Payment Service Provider, ASPSP) maksekonto andmeid.',
  'To use your payment account data, you must authenticate in the ASPSP service.': 'Maksekonto andmete kasutamiseks peate autentima ASPSP teenuses.',
  'The service was formerly known as Tilisy business API.': 'Teenus oli varem tuntud kui Tilisy business API.',
  'Your explicit consent to use the payment account data will be maintained in Enable Banking API.': 'Teie selget nõusolekut maksekonto andmete kasutamiseks säilitatakse Enable Banking API-s.',
  'This service registers your PSU authentication tokens and consent ID.': 'See teenus registreerib teie PSU autentimismärgid ja nõusoleku ID.',
  'Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.': 'Kui olete ASPSP teenuses nõusoleku andnud, avab Enable Banking API juurdepääsu teie maksekonto andmetele, et teie kasutatav digitaalne teenus (st autentimise algataja) saaks need andmed kätte saada.',
  'Your payment account data flows through Enable Banking API and won\'t be registered there.': 'Teie maksekonto andmed liiguvad läbi Enable Banking API ja neid ei registreerita seal.',
  'Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.': 'Enable Banking API omanik ja haldaja on Enable Banking Oy, aadress Otakaari 5, 02150 Espoo, Soome, ettevõtte ID 2988499-7.',
  'The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).': 'Ettevõte on registreeritud teenusepakkuja (kontoteabeteenuse pakkuja, AISP) ja selle üle teostab järelevalvet Soome finantsinspektsioon (Finantsinspektsioon).',
  'The registration details can be found from {0}.': 'Registreerimise üksikasjad leiate lehelt {0}.',
  'the FIN-FSA register for payment services providers': 'makseteenuse pakkujate register FIN-FSA',
  'Please read the {0} carefully before continuing to authentication.': 'Enne autentimise jätkamist lugege {0} hoolikalt läbi.',
  'You can check the terms and additional information on Enable Banking API service anytime from {0}.': 'Enable Banking API teenuse tingimusi ja lisateavet saate igal ajal vaadata aadressilt {0}.',
  'Information and updates on service or fraudulent or corresponding activity threatening the secure use of the service will be communicated to you by your digital service provider together with Enable Banking Oy.': 'Teavet ja värskendusi teenuse või pettuse või vastava tegevuse kohta, mis ohustab teenuse turvalist kasutamist, edastab teile teie digitaalteenuse pakkuja koos Enable Banking Oy-ga.',
  // Terms of Service
  'Terms of Service': 'Kasutustingimused',
  'Last updated:': 'Viimati uuendatud:',
  'Please read these terms of service carefully before using Enable Banking API.': 'Enne Enable Banking API kasutamist lugege need teenusetingimused hoolikalt läbi.',
  'Description of the service': 'Teenuse kirjeldus',
  'Processing of personal data': 'Isikuandmete töötlemine',
  'Enable Banking processes personal data only for the purpose and to the extent that Enable Banking API service can function for you.': 'Enable Banking töötleb isikuandmeid ainult sel eesmärgil ja ulatuses, et Enable Banking API teenus saaks teie jaoks toimida.',
  'When processing personal data Enable Banking API undertakes to comply with the legislation relating to processing of personal data and data protection as well as other legislation applicable and especially the EU General Data Protection Regulation (EU 2016/679).': 'Isikuandmete töötlemisel kohustub Enable Banking API järgima isikuandmete töötlemist ja andmekaitset käsitlevaid õigusakte, samuti muid kohaldatavaid õigusakte, eriti ELi isikuandmete kaitse üldmäärust (EL 2016/679).',
  'The more detailed contents of the personal data processing, including:': 'Isikuandmete töötlemise üksikasjalikum sisu, sealhulgas:',
  '(a) the nature and purpose of the processing;': '(a) töötlemise laad ja eesmärk;',
  '(b) the type of personal data and categories of data subjects;': '(b) isikuandmete tüüp ja andmesubjektide kategooriad;',
  '(c) the applicable data protection measures;': '(c) kohaldatavad andmekaitsemeetmed;',
  '(d) the customer’s rights and responsibilities;': '(d) kliendi õigused ja kohustused;',
  'and (e) the object and duration of the personal data processing shall be described in greater detail in the Privacy Policy.': 'ja (e) isikuandmete töötlemise objekti ja kestust kirjeldatakse üksikasjalikumalt privaatsuspoliitikas.',
  'Links to other websites': 'Lingid teistele veebisaitidele',
  'Enable Banking may contain links or redirect you to third-party web sites or services that are not owned or controlled by the Company, including but not limited to ASPSPs’ web sites or services.': 'Enable Banking võib sisaldada linke või suunata teid kolmandate osapoolte veebisaitidele või teenustele, mis ei ole ettevõtte omandis ega kontrolli all, sealhulgas, kuid mitte ainult, ASPSP-de veebisaitidele või teenustele.',
  'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.': 'Ettevõte ei kontrolli kolmandate osapoolte veebisaitide ega teenuste sisu, privaatsuseeskirju ega tavasid ega võta endale nende eest vastutust.',
  'You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.': 'Lisaks mõistate ja nõustute, et ettevõte ei vastuta otseselt ega kaudselt mis tahes kahjude eest, mis on põhjustatud või väidetavalt põhjustatud sellise sisu, kaupade või teenuste kasutamisest või nendele tuginemisest, või selliste veebisaitide või teenuste kaudu.',
  'We strongly advise you to read terms and conditions and privacy policies of any third-party web sites or services that you visit.': 'Soovitame tungivalt tutvuda kõigi külastatud kolmandate osapoolte veebisaitide või teenuste tingimustega ja privaatsuseeskirjadega.',
  'No warranty': 'Ilma garantiita',
  'Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied:': 'Eelöeldut piiramata ei anna ettevõte ega ükski selle pakkuja mingisugust otsest ega kaudset kindlustust ega garantiid:',
  '(i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon;': '(i) teenuse toimimise või kättesaadavuse või selles sisalduva teabe, sisu ning materjalide või toodete kohta;',
  '(ii) that the Service will be uninterrupted or error-free;': '(ii) et Teenus on katkematu või tõrgeteta;',
  '(iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.': '(iii) teenuse kaudu pakutava teabe või sisu täpsuse, usaldusväärsuse või valuuta kohta.',
  'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.': 'Mõnede riikide õigusaktid ei luba  tarbija seadustest tulenevatele õigustele seatud teatud tüüpi garantiide või piirangute väljajätmist, mistõttu mõned või kõik ülaltoodud erandid ja piirangud ei pruugi teie suhtes kehtida.',
  'But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.': 'Kuid sellisel juhul rakendatakse käesolevas jaotises sätestatud erandeid ja piiranguid kohaldatava seaduse alusel võimalikult suures ulatuses.',
  'Changes': 'Muudatused',
  'The Company reserves the right to modify or replace the Service at any time.': 'Ettevõte jätab endale õiguse Teenust igal ajal muuta või asendada.',
  'If a revision is material the Company will make reasonable efforts to notify you prior to any new terms taking effect together with other digital service providers.': 'Kui muudatus on oluline, teeb ettevõte mõistlikke jõupingutusi, et teavitada teid enne uute tingimuste jõustumist koos teiste digitaalteenuste pakkujatega.',
  'You can also check any updates from this page.': 'Sellelt lehelt saate kontrollida ka värskendusi.',
  'By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms.': 'Jätkates Teenusele juurdepääsu või selle kasutamist pärast nende muudatuste jõustumist, nõustute muudetud tingimustega.',
  'If you do not agree to the new terms, in whole or in part, please stop using the Service.': 'Kui te ei nõustu täielikult või osaliselt uute tingimustega, lõpetage palun Teenuse kasutamine.',
  'Termination': 'Lõpetamine',
  'If for any reason, you no longer wish to use the Service to share your payment accounts data with other digital services, please {0}.': 'Kui te mingil põhjusel ei soovi enam Teenust kasutada oma maksekonto andmete jagamiseks teiste digitaalsete teenustega, siis palun {0}.',
  'visit the Data Sharing Consents page': 'külastage Andmete Jagamise Nõusoleku lehte',
  'Upon termination, your right to use the Service will cease immediately.': 'Lõpetamisel lõpeb teie õigus kasutada Teenust kohe.',
  'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever.': 'Võime teie juurdepääsu viivitamatult lõpetada või peatada  mis tahes põhjusel, ilma ette teatamata ja vastutuseta.',
  'Governing law': 'Kohaldatav seadus',
  'The laws of Finland shall govern your use of the Service.': 'Teenuse kasutamist reguleerivad Soome seadused.',
  'If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.': 'Kui olete Euroopa Liidu tarbija, võivad teile tulla kasuks selle riigi seaduste kohustuslikud sätted, kus te elate.',
  'Disputes resolution': 'Vaidluste lahendamine',
  'If you have any concern or dispute about the Service, at first, you are asked for trying to resolve the dispute informally by contacting the Company.': 'Kui teil on Teenusega seoses muresid või vaidlusi, palutakse teil kõigepealt proovida lahendada vaidlus mitteametlikult, pöördudes meie ettevõtte poole.',
  'The consumer users in Finland are also entitled to be in contact with Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, kril@oikeus.fi.': 'Soomes asuvatel tarbijakasutajatel on õigus pöörduda ka Tarbijavaidluste Ameti poole (Kuluttajariitalautakunta), Hämeentie 3, P.O. Box 306, 00531 Helsingi, tel. +358 29 566 5200, kril@oikeus.fi.',
  'The company related issues shall be directed to the supervising authority.': 'Ettevõttega seotud küsimused suunatakse järelevalveasutusele.',
  'The FIN-FSA contact information is: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (switchboard); www.finanssivalvonta.fi.': 'Finantsinspektsiooni kontaktandmed on: Snellmaninkatu 6, 00100 Helsingi, Soome, tel. +358 9 183 51 (kodukeskjaam); www.finanssivalvonta.fi.',
  'Contact us': 'Võtke meiega ühendust',
  'If you have any questions about this terms of service, you can contact us by email at {0}.': 'Kui teil on nende teenusetingimuste kohta küsimusi, võite meiega ühendust võtta e-posti aadressil {0}.',
  // Backend messages
  '10 digit client number': '10-kohaline kliendinumber',
  '10 digit organisation number (or 12 digit personal number if the company is a sole proprietorship)': '10-kohaline organisatsiooninumber (või 12-kohaline isikukood, kui ettevõte on füüsilisest isikust ettevõtja)',
  '10 digit organization number.': '10-kohaline organisatsiooni number.',
  '17 digit card number (starting with 6703)': '17-kohaline kaardinumber (alates numbrist 6703)',
  '6 digit Handelsbanken customer number': '6-kohaline Handelsbankeni kliendinumber',
  '8 digits CVR number': '8-kohaline CVR number',
  '9 digits Organisasjonsnummer': '9-kohaline Organisasjonsnummer',
  'Agreement number': 'Lepingu number',
  'Agreement number for Nordea Corporate Netbank': 'Nordea korporatiivse internetipanga lepingu number',
  'Bank user ID': 'Panga kasutajatunnus',
  'Business identity code (Y-tunnus) in 1234567-8 format': 'Ettevõtte kood (Y-tunnus) formaadis 1234567-8',
  'Business identity code which is displayed in the Corporate Internet bank settings': 'Ettevõtte isikukood, mis kuvatakse ettevõtte Internetipanga seadetes',
  'Client ID': 'Kliendi ID',
  'Client number': 'Kliendi number',
  'Company ID': 'Ettevõtte ID',
  'Company number': 'Ettevõtte number',
  'Corporate ID': 'Ettevõtte ID',
  'Corporate registration ID': 'Ettevõtte registreerimistunnus',
  'Currency code': 'Valuuta kood',
  'Digit card number': 'Numbrikaardi number',
  'Disposer': 'Kõrvaldaja',
  'E-mail': 'E-post',
  'E-mail used for authenticating to Holvi': 'Holvi autentimiseks kasutatud e-post',
  'Either the 14 digit SEB identifier for the company or the 10 digit organization number': 'Kas ettevõtte 14-kohaline SEB tunnus või 10-kohaline organisatsiooni number',
  'FKDN (without subaccount number): 3 digit branch number + 7 digit customer number': 'FKDN (ilma alamkonto numbrita): 3-kohaline kontorinumber + 7-kohaline kliendinumber',
  'For Swedish Corporates, this is their Organisation number or SHB number, and for Sole Traders (Enskild firma), it is their Personal number, all of which are 10 digits.': 'Rootsi ettevõtete jaoks on see organisatsiooni number või SHB number ja üksikettevõtjate (Enskild firma) isiklik number, mis kõik on 10-kohalised.',
  'IBAN': 'IBAN',
  'ID, NIE, Passport': 'ID, NIE, pass',
  'Login ID used in MyBRD aplications': 'MyBRD rakendustes kasutatav sisselogimise ID',
  'Login Name': 'Kasutaja nimi',
  'Mandate ID': 'Mandaadi ID',
  'Norwegian national identity number in the format DDMMYYXXXXX (11 digits)': 'Norra isikukood vormingus DDMMYYXXXXX (11 numbrit)',
  'Online banking PIN': 'Internetipanga PIN-kood',
  'Online banking Password': 'Internetipanga parool',
  'Organisation ID in the format TBxxxxx or TXxxxxx': 'Organisatsiooni ID vormingus TBxxxxx või TXxxxxx',
  'Organisation number': 'Organisatsiooni number',
  'PIN': 'PIN-kood',
  'Password': 'Parool',
  'Password for Aktia Internet bank': 'Aktia internetipanga parool',
  'Password used for authenticating to Holvi': 'Holvi autentimiseks kasutatud parool',
  'Personal ID': 'Isiklik ID',
  'Personal ID number': 'Isikukood',
  'Personal code': 'Isikukood',
  'Personal code of the user': 'Kasutaja isikukood',
  'Personal signatory number': 'Isiklik allkirjastaja number',
  'Personal username for online banking': 'Isiklik kasutajanimi internetipanga jaoks',
  'Postbank ID': 'Postipanga ID',
  'Social security number (Personnummer)': 'Sotsiaalkindlustuse number (Personnummer)',
  'Social security number in the format YYYYMMDDXXXX': 'Sotsiaalkindlustusnumber vormingus YYYYMMDDXXXX',
  'Swedish social security number in the format YYYYMMDDXXXX': 'Rootsi sotsiaalkindlustuse number vormingus YYYYMMDDXXXX',
  'The IBAN of the user authenticating': 'Autentiva kasutaja IBAN',
  'The currency code': 'Valuuta kood',
  'The user ID of the user authenticating': 'Autentiva kasutaja ID',
  'Unique personal ID number': 'Unikaalne isikukood',
  'User ID': 'Kasutaja ID',
  'User ID for Aktia Internet bank': 'Aktia internetipanga kasutajatunnus',
  'User ID for Nordea Corporate Netbank': 'Nordea Corporate Netipanga kasutajatunnus',
  'User ID for online banking': 'Kasutajatunnus internetipanga jaoks',
  'User ID in the format XX000000': 'Kasutaja ID vormingus XX000000',
  'User ID used to log in to the bank': 'Panka sisselogimiseks kasutatav kasutajatunnus',
  'Username': 'Kasutajanimi',
  'Username used to log in to the bank': 'Panka sisselogimiseks kasutatav kasutajanimi',
  'VR NetKey': 'VR NetKey',
  'WebSSO user name': 'WebSSO kasutajanimi'
}
