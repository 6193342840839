<template>
  <div id="app" class="d-flex flex-column">
    <main class="main-content container py-3">
      <LangSwitch class="float-right" />
      <router-view v-slot="{ Component }">
        <transition name="page">
          <component :is="Component" />
        </transition>
      </router-view>
    </main>
  </div>
</template>

<script>
import LangSwitch from './components/elements/LangSwitch.vue'

export default {
  components: {
    LangSwitch
  }
}
</script>

<style lang="scss">
@import "./style/app.scss";
#app{
  min-height: 100vh;
}
.main-content{
  flex: 1;
}
.page-enter-active, .page-leave-active {
  transition: all 0.2s;
}
.page-enter {
  opacity: 0;
  transform: translateY(10px);
}
</style>
