import { createStore } from 'vuex'

export default createStore({
  state () {
    return {
      loading: false,
      errorMessage: ''
    }
  },
  mutations: {
  },
  getters: {
  },
  actions: {
  }
})
