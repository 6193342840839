export default {
  publicOrigin: 'https://tilisy.enablebanking.com',
  publicSandboxOrigin: 'https://tilisy-sandbox.enablebanking.com',
  baseApiUrl: '/',
  baseLanguage: 'EN',
  countryLocales: {
    EE: 'ET',
    FI: 'FI',
    FR: 'FR',
    LT: 'LT',
    LV: 'LV',
    SE: 'SV'
  }
}
