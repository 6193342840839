<template>
  <div>
    <p><strong>{{ $t('About Enable Banking API') }}</strong></p>
    <p class="text-justify">
      <span>{{ $t('Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.') }}</span>
      <span>{{ $t('To use your payment account data, you must authenticate in the ASPSP service.') }}</span>
    </p>
    <p class="text-justify">
      <span>{{ $t('Your explicit consent to use the payment account data will be maintained in Enable Banking API.') }}</span>
      <span>{{ $t('This service registers your PSU authentication tokens and consent ID.') }}</span>
      <span>{{ $t('Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.') }}</span>
      <span>{{ $t('Your payment account data flows through Enable Banking API and won\'t be registered there.') }}</span>
    </p>
    <p class="text-justify">
      <span>{{ $t('Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.') }}</span>
      <span>{{ $t('The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).') }}</span>
      <i18n tag="span" path="The registration details can be found from {0}."><a href="https://www.finanssivalvonta.fi/en/registers/supervised-entities/" target="_blank">{{ $t('the FIN-FSA register for payment services providers') }}</a></i18n>
    </p>
    <p class="text-justify">
      <i18n tag="span" path="Please read the {0} carefully before continuing to authentication."><a href="https://tilisy.enablebanking.com/terms" target="_blank">{{ $t('terms of Enable Banking API service') }}</a></i18n>
      <i18n tag="span" path="You can check the terms and additional information on Enable Banking API service anytime from {0}."><a href="https://tilisy.enablebanking.com/terms" target="_blank">https://tilisy.enablebanking.com/terms</a></i18n>
      <span>{{ $t('Information and updates on service or fraudulent or corresponding activity threatening the secure use of the service will be communicated to you by your digital service provider together with Enable Banking Oy.') }}</span>
    </p>
  </div>
</template>
<script>

export default {
  name: 'AboutService'
}
</script>
<style lang="scss" scoped>
span:not(:last-child):after {
  content: ' ';
}
</style>
