import { createI18n } from 'vue-i18n'
import config from './config'
import DA from './lang/da'
import DE from './lang/de'
import EN from './lang/en'
import ES from './lang/es'
import ET from './lang/et'
import FI from './lang/fi'
import FR from './lang/fr'
import IS from './lang/is'
import IT from './lang/it'
import LT from './lang/lt'
import LV from './lang/lv'
import NL from './lang/nl'
import NO from './lang/no'
import PL from './lang/pl'
import RU from './lang/ru'
import SV from './lang/sv'

export const i18n = createI18n({
  locale: config.baseLanguage,
  fallbackLocale: config.baseLanguage,
  formatFallbackMessages: true,
  legacy: true, // Enable legacy mode for Options API
  globalInjection: true, // Enable global injection
  messages: {
    DA,
    DE,
    EN,
    ES,
    ET,
    FI,
    FR,
    IS,
    IT,
    LT,
    LV,
    NL,
    NO,
    PL,
    RU,
    SV
  }
})

export const setLocale = (locale) => {
  i18n.global.locale = locale
}
