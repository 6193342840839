<template>
  <div class="enablebanking-home loading-content">
    <div class="enablebanking-status text-center">
      <Spinner v-if="loading" class="enablebanking-status-spinner mx-auto" />
      <div class="enablebanking-status-info text-dark">{{ message }}</div>
      <div class="enablebanking-status-error text-danger">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import Spinner from '../components/elements/Spinner.vue'
import BaseService from '../services/BaseService.js'
export default {
  name: 'Home',
  components: {
    Spinner
  },
  data () {
    return {
      loading: true,
      message: '',
      error: ''
    }
  },
  async mounted () {
    try {
      const r = await BaseService.getNextPage()
      const redirectUrl = r.data.redirect_url
      if (redirectUrl) {
        window.location.href = redirectUrl
      }
    } catch (e) {
      this.error = this.$t('Authentication session has expired')
      this.loading = false
    }
  }
}
</script>

<style>
.loading-content {
  padding-top: 20vh;
}
</style>
