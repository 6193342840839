export default {
  // General
  'About {appName}': 'Om {appName}',
  'About Enable Banking API': 'Om Enable Banking API',
  'Application description is not provided.': 'Ansøgningsbeskrivelse gives ikke.',
  'Authenticate': 'Godkend',
  'Authentication method': 'Godkendelsesmetode',
  'Authentication session has expired': 'Godkendelsessessionen er udløbet',
  'Cancel': 'Afbestille',
  'Click {0} button if you have confirmed authorization in the BankID app.': 'Klik på knappen {0}, hvis du har bekræftet autorisation i BankID-appen.',
  'Continue authentication': 'Fortsæt godkendelse',
  'Enter your OTP code with index {0}': 'Indtast din OTP-kode med indeks {0}',
  'Error during authentication:': 'Fejl under godkendelse:',
  'Error while updating authentication status': 'Fejl under opdatering af godkendelsesstatus',
  'Open the BankID app on this device': 'Åbn BankID-appen på denne enhed',
  'OTP code': 'OTP-kode',
  'Redirecting to your account servicing provider': 'Omdirigerer til din kontoserviceudbyder',
  'Returning to the authentication initiator': 'Vender tilbage til godkendelsesinitiatoren',
  'Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page': 'Start BankID-appen på din mobile enhed, og tryk på knappen QR-kode i appen for at scanne QR-koden på denne side',
  'or': 'eller',
  'Starting authorization': 'Start autorisation',
  'Start typing for filtering the list': 'Begynd at skrive for at filtrere listen',
  'To continue please use your authentication app': 'Brug din godkendelsesapp for at fortsætte',
  'Waiting for completion of authentication': 'Venter på fuldførelse af godkendelse',
  'Try again': 'Prøv igen',
  'Submit': 'Indsend',
  'Unable to access server': 'Kan ikke få adgang til serveren',
  'Unable to authenticate': 'Kan ikke godkendes',
  'Unable to continue authorization': 'Kunne ikke fortsætte godkendelsen',
  'Unable to continue decoupled authorization': 'Kunne ikke fortsætte afkoblet godkendelse',
  'Unable to handle authentication status "{status}"': 'Kan ikke håndtere godkendelsesstatus "{status}"',
  'Unknown error occurred': 'Der opstod en ukendt fejl',
  'You need to close this window': 'Du skal lukke dette vindue',
  // Consent
  'Authentication is initiated by {0}.': 'Godkendelse initieres af {0}.',
  'After you complete authentication, {0} will be shared with the authentication initiator.': 'Når du har gennemført godkendelsen, deles {0} med godkendelsesinitiatoren.',
  'your payment accounts data': 'dine betalingskontodata',
  'the list of your payment accounts': 'listen over dine betalingskonti',
  'the list of your payment accounts and accounts balances': 'listen over dine betalingskonti og konti saldi',
  'the list of your payment accounts and accounts transactions': 'listen over dine betalingskonti og konti transaktioner',
  'the list of your payment accounts, accounts balances and transactions': 'listen over dine betalingskonti, konti saldi og transaktioner',
  'By pressing {0} button you agree with the {1}.': 'Ved at trykke på knappen {0} accepterer du {1}.',
  'terms of Enable Banking API service': 'vilkår for Enable Banking API-tjeneste',
  'Press {0} button if you are not willing to share your payment accounts data.': 'Tryk på knappen {0}, hvis du ikke er villig til at dele dine betalingskontodata.',
  // About Tilisy
  'Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.': 'Enable Banking API er en onlinetjeneste, der giver dig mulighed for at bruge dine betalingskontodata fra en valgt bank eller en banklignende organisation (benævnt "ASPSP" = "Account Servicing Payment Service Provider"), f.eks. et kreditkortselskab, i andre digitale tjenester.',
  'To use your payment account data, you must authenticate in the ASPSP service.': 'For at bruge dine betalingskontodata skal du godkende dette i ASPSP-tjenesten.',
  'The service was formerly known as Tilisy business API.': 'Tjenesten var tidligere kendt som Tilisy business API.',
  'Your explicit consent to use the payment account data will be maintained in Enable Banking API.': 'Dit udtrykkelige samtykke til at bruge betalingskontodataene bevares i Enable Banking API.',
  'This service registers your PSU authentication tokens and consent ID.': 'Denne service registrerer dine PSU-godkendelsestokens og samtykke-id.',
  'Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.': 'Når du har givet samtykke i ASPSP-tjenesten, åbner Enable Banking API adgang til dine betalingskontodata, så den digitale tjeneste, du bruger (dvs. godkendelsesinitiatoren), kan hente disse data.',
  'Your payment account data flows through Enable Banking API and won\'t be registered there.': 'Dine betalingskontodata behandles i Enable Banking API men gemmes IKKE her.',
  'Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.': 'Enable Banking API ejes og drives af Enable Banking Oy, der ligger i Otakaari 5, 02150 Espoo, Finland, virksomheds-ID 2988499-7.',
  'The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).': 'Virksomheden er en registreret tjenesteudbyder (Account Information Service Provider, AISP) og overvåges af den finske finanstilsyn (FIN-FSA).',
  'The registration details can be found from {0}.': 'Registreringsoplysningerne kan findes på {0}.',
  'the FIN-FSA register for payment services providers': 'FIN-FSA-registeret for betalingstjenesteudbydere',
  'Please read the {0} carefully before continuing to authentication.': 'Læs {0} omhyggeligt, inden du fortsætter med godkendelse.',
  'You can check the terms and additional information on Enable Banking API service anytime from {0}.': 'Du kan kontrollere vilkårene og yderligere oplysninger om Enable Banking API-service når som helst fra {0}.',
  'Information and updates on service or fraudulent or corresponding activity threatening the secure use of the service will be communicated to you by your digital service provider together with Enable Banking Oy.': 'Oplysninger og opdateringer om service eller svigagtig eller tilsvarende aktivitet, der truer sikker brug af tjenesten, vil blive meddelt dig af din digitale tjenesteudbyder sammen med Enable Banking Oy.',
  // Terms of Service
  'Terms of Service': 'Servicevilkår',
  'Last updated:': 'Sidst opdateret:',
  'Please read these terms of service carefully before using Enable Banking API.': 'Læs disse servicevilkår omhyggeligt, inden du bruger Enable Banking API.',
  'Description of the service': 'Beskrivelse af tjenesten',
  'Processing of personal data': 'Behandling af personoplysninger',
  'Enable Banking processes personal data only for the purpose and to the extent that Enable Banking API service can function for you.': 'Enable Banking behandler kun personoplysninger med det formål og i det omfang, at Enable Banking API-tjeneste kan fungere for dig.',
  'When processing personal data Enable Banking API undertakes to comply with the legislation relating to processing of personal data and data protection as well as other legislation applicable and especially the EU General Data Protection Regulation (EU 2016/679).': 'Ved behandling af personoplysninger forpligter Enable Banking API sig til at overholde lovgivningen vedrørende behandling af personoplysninger og databeskyttelse samt anden gældende lovgivning og især EUs generelle databeskyttelsesforordning (EU 2016/679).',
  'The more detailed contents of the personal data processing, including:': 'Det mere detaljerede indhold af behandlingen af ​​personoplysninger, herunder:',
  '(a) the nature and purpose of the processing;': '(a) behandlingens art og formål',
  '(b) the type of personal data and categories of data subjects;': '(b) typen af ​​personoplysninger og kategorier af registrerede',
  '(c) the applicable data protection measures;': '(c) de gældende databeskyttelsesforanstaltninger',
  '(d) the customer’s rights and responsibilities;': '(d) kundens rettigheder og ansvar',
  'and (e) the object and duration of the personal data processing shall be described in greater detail in the Privacy Policy.': 'og (e) formålet og varigheden af ​​behandlingen af ​​personoplysninger skal beskrives mere detaljeret i fortrolighedspolitikken.',
  'Links to other websites': 'Links til andre websteder',
  'Enable Banking may contain links or redirect you to third-party web sites or services that are not owned or controlled by the Company, including but not limited to ASPSPs’ web sites or services.': 'Enable Banking kan indeholde links eller omdirigere dig til tredjepartswebsteder eller -tjenester, der ikke ejes eller kontrolleres af virksomheden, inklusive men ikke begrænset til ASPSPs websteder eller tjenester.',
  'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.': 'Virksomheden har ingen kontrol over og påtager sig intet ansvar for indholdet, fortrolighedspolitikker eller praksis på tredjepartswebsteder eller -tjenester.',
  'You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.': 'Du anerkender og accepterer endvidere, at virksomheden ikke er ansvarlig eller ansvarlig, direkte eller indirekte, for skader eller tab, der er forårsaget eller påstået at være forårsaget af eller i forbindelse med brugen af ​​eller tillid til sådant indhold, varer eller tjenester tilgængelige på eller gennem sådanne websteder eller tjenester.',
  'We strongly advise you to read terms and conditions and privacy policies of any third-party web sites or services that you visit.': 'Vi råder dig på det kraftigste til at læse vilkår og betingelser og fortrolighedspolitikker på tredjepartswebsteder eller -tjenester, du besøger.',
  'No warranty': 'Ingen garanti',
  'Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied:': 'Uden at begrænse det foregående fremsætter hverken virksomheden eller nogen af ​​virksomhedens leverandører nogen form for garanti af nogen art, udtrykkeligt eller underforstået:',
  '(i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon;': '(i) med hensyn til driften eller tilgængeligheden af ​​Tjenesten eller de oplysninger, indhold og materialer eller produkter, der er inkluderet deri;',
  '(ii) that the Service will be uninterrupted or error-free;': '(ii) at Tjenesten vil være uafbrudt eller fejlfri;',
  '(iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.': '(iii) med hensyn til nøjagtigheden, pålideligheden eller valutaen for enhver information eller indhold, der leveres gennem tjenesten.',
  'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.': 'Nogle jurisdiktioner tillader ikke udelukkelse af visse typer garantier eller begrænsninger af gældende lovmæssige rettigheder for en forbruger, så nogle eller alle ovenstående undtagelser og begrænsninger gælder muligvis ikke for dig.',
  'But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.': 'Men i et sådant tilfælde skal de undtagelser og begrænsninger, der er anført i dette afsnit, anvendes i videst muligt omfang håndhæves i henhold til gældende lov.',
  'Changes': 'Ændringer',
  'The Company reserves the right to modify or replace the Service at any time.': 'Virksomheden forbeholder sig retten til når som helst at ændre eller udskifte tjenesten.',
  'If a revision is material the Company will make reasonable efforts to notify you prior to any new terms taking effect together with other digital service providers.': 'Hvis en revision er væsentlig, vil virksomheden gøre en rimelig indsats for at underrette dig, inden nye vilkår træder i kraft sammen med andre digitale tjenesteudbydere.',
  'You can also check any updates from this page.': 'Du kan også kontrollere eventuelle opdateringer fra denne side.',
  'By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms.': 'Ved at fortsætte med at få adgang til eller bruge Tjenesten, efter at disse revisioner træder i kraft, accepterer du at være bundet af de reviderede vilkår.',
  'If you do not agree to the new terms, in whole or in part, please stop using the Service.': 'Hvis du ikke accepterer de nye vilkår helt eller delvist, bedes du stoppe med at bruge tjenesten.',
  'Termination': 'Afslutning',
  'If for any reason, you no longer wish to use the Service to share your payment accounts data with other digital services, please {0}.': 'Hvis du af en eller anden grund ikke længere ønsker at bruge Tjenesten til at dele dine betalingskontooplysninger med andre digitale tjenester, bedes du {0}.',
  'visit the Data Sharing Consents page': 'besøg siden Data Deling Samtykker',
  'Upon termination, your right to use the Service will cease immediately.': 'Efter opsigelsen ophører din ret til at bruge tjenesten med det samme.',
  'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever.': 'Vi kan afbryde eller suspendere din adgang med det samme uden forudgående varsel eller erstatningsansvar, uanset årsag.',
  'Governing law': 'Lovvalg',
  'The laws of Finland shall govern your use of the Service.': 'Finlands lovgivning regulerer din brug af tjenesten.',
  'If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.': 'Hvis du er en EU-forbruger, får du fordel af alle obligatoriske bestemmelser i lovgivningen i det land, hvor du er bosiddende.',
  'Disputes resolution': 'Tvisteløsning',
  'If you have any concern or dispute about the Service, at first, you are asked for trying to resolve the dispute informally by contacting the Company.': 'Hvis du har nogen bekymring eller uenighed om tjenesten, bliver du først bedt om at forsøge at løse tvisten uformelt ved at kontakte firmaet.',
  'The consumer users in Finland are also entitled to be in contact with Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, kril@oikeus.fi.': 'Forbrugerbrugerne i Finland er også berettiget til at være i kontakt med Forbrugertviststyrelsen, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tlf. +358 29 566 5200, kril@oikeus.fi.',
  'The company related issues shall be directed to the supervising authority.': 'Virksomhedsrelaterede spørgsmål rettes til tilsynsmyndigheden.',
  'The FIN-FSA contact information is: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (switchboard); www.finanssivalvonta.fi.': 'FIN-FSA kontaktoplysninger er: Snellmaninkatu 6, 00100 Helsinki, Finland, tlf. +358 9 183 51 (omstillingsbord); www.finanssivalvonta.fi.',
  'Contact us': 'Kontakt os',
  'If you have any questions about this terms of service, you can contact us by email at {0}.': 'Hvis du har spørgsmål om disse servicevilkår, kan du kontakte os via e-mail på {0}.',
  // Backend messages
  '10 digit client number': '10-cifret kundenummer',
  '10 digit organisation number (or 12 digit personal number if the company is a sole proprietorship)': '10-cifret organisationsnummer (eller 12-cifret personnummer, hvis virksomheden er en enkeltmandsvirksomhed)',
  '10 digit organization number.': '10-cifret organisationsnummer.',
  '17 digit card number (starting with 6703)': '17-cifret kortnummer (startende med 6703)',
  '6 digit Handelsbanken customer number': '6-cifret Handelsbanken kundenummer',
  '8 digits CVR number': '8 cifret CVR-nummer',
  '9 digits Organisasjonsnummer': '9 cifre Organisationsnummer',
  'Agreement number': 'Aftalenummer',
  'Agreement number for Nordea Corporate Netbank': 'Aftalenummer for Nordea Corporate Netbank',
  'Bank user ID': 'Bank bruger-id',
  'Business identity code (Y-tunnus) in 1234567-8 format': 'Virksomhedsidentitetskode (Y-tunnus) i 1234567-8 format',
  'Business identity code which is displayed in the Corporate Internet bank settings': 'Virksomhedsidentitetskode, som vises i virksomhedens internetbankindstillinger',
  'Client ID': 'Klient-id',
  'Client number': 'Kundenummer',
  'Company ID': 'Firma-id',
  'Company number': 'Firmanummer',
  'Corporate ID': 'Virksomheds-id',
  'Corporate registration ID': 'Firmaregistrerings-id',
  'Currency code': 'Valutakode',
  'Digit card number': 'Cifret kortnummer',
  'Disposer': 'Disponer',
  'E-mail': 'E-mail',
  'E-mail used for authenticating to Holvi': 'E-mail brugt til at godkende Holvi',
  'Either the 14 digit SEB identifier for the company or the 10 digit organization number': 'Enten det 14-cifrede SEB-id for virksomheden eller det 10-cifrede organisationsnummer',
  'FKDN (without subaccount number): 3 digit branch number + 7 digit customer number': 'FKDN (uden underkontonummer): 3-cifret filialnummer + 7-cifret kundenummer',
  'For Swedish Corporates, this is their Organisation number or SHB number, and for Sole Traders (Enskild firma), it is their Personal number, all of which are 10 digits.': 'For svenske virksomheder er dette deres organisationsnummer eller SHB-nummer, og for enkeltmandsvirksomheder (Enskild firma) er det deres personnummer, som alle er 10 cifre.',
  'IBAN': 'IBAN',
  'ID, NIE, Passport': 'ID, NIE, pas',
  'Login ID used in MyBRD aplications': 'Login-id brugt i MyBRD-applikationer',
  'Login Name': 'Login-navn',
  'Mandate ID': 'Mandat ID',
  'Norwegian national identity number in the format DDMMYYXXXXX (11 digits)': 'Norsk nationalt identitetsnummer i formatet DDMMYYXXXXXX (11 cifre)',
  'Online banking PIN': 'PIN-kode til netbank',
  'Online banking Password': 'Adgangskode til netbank',
  'Organisation ID in the format TBxxxxx, TXxxxxx, TUxxxxx or NUxxxxx': 'Organisations-id i formatet TBxxxxx, TXxxxxx, TUxxxxx eller NUxxxxx',
  'Organisation number': 'Organisationsnummer',
  'PIN': 'PIN',
  'Password': 'Adgangskode',
  'Password for Aktia Internet bank': 'Adgangskode til Aktia Internetbank',
  'Password used for authenticating to Holvi': 'Adgangskode brugt til at godkende Holvi',
  'Personal ID': 'Personligt ID',
  'Personal ID number': 'Personligt ID-nummer',
  'Personal code': 'Personlig kode',
  'Personal code of the user': 'Brugerens personlige kode',
  'Personal signatory number': 'Personligt underskrivernummer',
  'Personal username for online banking': 'Personligt brugernavn til netbank',
  'Postbank ID': 'Postbank ID',
  'Social security number (Personnummer)': 'CPR-nummer (Personnummer)',
  'Social security number in the format YYYYMMDDXXXX': 'CPR-nummer i formatet ÅÅÅÅMMDDXXXX',
  'Swedish social security number in the format YYYYMMDDXXXX': 'Svensk personnummer i formatet ÅÅÅÅMMDDXXXX',
  'The IBAN of the user authenticating': 'IBAN for den bruger, der godkender',
  'The currency code': 'Valutakoden',
  'The user ID of the user authenticating': 'Bruger-id\'et for den bruger, der godkender',
  'Unique personal ID number': 'Unikt personnummer',
  'User ID': 'Bruger-id',
  'User ID for Aktia Internet bank': 'Bruger-id til Aktia Internetbank',
  'User ID for Nordea Corporate Netbank': 'Bruger-id til Nordea Corporate Netbank',
  'User ID for online banking': 'Bruger-id til netbank',
  'User ID in the format XX000000': 'Bruger-id i formatet XX000000',
  'User ID used to log in to the bank': 'Bruger-id brugt til at logge ind i banken',
  'Username': 'Brugernavn',
  'Username used to log in to the bank': 'Brugernavn brugt til at logge ind i banken',
  'VR NetKey': 'VR NetKey',
  'WebSSO user name': 'WebSSO brugernavn'
}
