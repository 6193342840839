<template>
  <div>
    <transition name="modal">
      <div class="modal fade show" tabindex="-1" role="dialog" v-if="isShow">
        <div class="modal-dialog" role="document" :class="`modal-${size}`">
          <div class="modal-content">
            <div class="modal-body modal-vh-scroll" :class="{'modal-vh-scroll--with-footer': isShowFooter}">
              <slot/>
            </div>
            <div class="modal-footer p-3 justify-content-center" v-if="isShowFooter">
              <button
                type="button"
                class="btn btn-light"
                v-on:click="handleClose"
                v-if="isShowCancel">{{ cancelText }}</button>
              <button
                type="button"
                class="btn btn-theme"
                v-on:click="handleAccept"
                v-if="isShowAccept">{{ acceptText }}</button>
            </div>
          </div>
        </div>
        <div class="modal-backdrop fade show" v-on:click="isBackClickable ? $emit('close') : () => {}"></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data () {
    return {
      resolve: null
    }
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    isShowFooter: {
      type: Boolean,
      default: true
    },
    isShowCancel: {
      type: Boolean,
      default: true
    },
    isShowAccept: {
      type: Boolean,
      default: true
    },
    isBackClickable: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'md'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    acceptText: {
      type: String,
      default: 'Accept'
    }
  },
  mounted () {
    this.confirm()
  },
  methods: {
    confirm () {
      return new Promise(resolve => {
        this.resolve = resolve
      })
    },
    handleClose () {
      this.resolve(false)
      this.$emit('close')
    },
    handleAccept () {
      this.resolve(true)
      this.$emit('accept')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/variables";

.modal{
  display: block;
  &:before{
    content: '';
  }
}

.modal-content{
  box-shadow: $e-shadow-lg;
}

.modal-dialog{
  min-height: calc(100% - 3.5rem);
  display: flex;
  z-index: $zindex-modal;
  width: 100%;
  align-items: center;
  margin: auto;
  padding: 10px;
}
.modal-enter-active, .modal-leave-active {
  transform: scale(1);
  transition: all 0.2s;
}
.modal-enter, .modal-leave-to {
  opacity: 0;
}
.modal-enter {
  transform: scale(1.1);
}
.modal-leave-to {
  transform: scale(0.9);
  .modal-backdrop{
    display: none;
  }
}
.modal-vh-scroll{
  $max-size: 96vh;

  max-height: $max-size;
  overflow-x: hidden;
  overflow-y: auto;
  &--with-footer{
    max-height: calc(#{$max-size} - 68px);
  }
}
</style>
