export default{
  // General
  'About {appName}': 'О {appName}',
  'About Enable Banking API': 'О Enable Banking API',
  'Application description is not provided.': 'Описание приложения не предоставлено.',
  'Authenticate': 'Аутентифицироваться',
  'Authentication method': 'Метод аутентификации',
  'Authentication session has expired': 'Сеанс аутентификации истек',
  'Cancel': 'Отмена',
  'Click {0} button if you have confirmed authorization in the BankID app.': 'Нажмите кнопку {0}, если вы подтвердили авторизацию в приложении BankID.',
  'Continue authentication': 'Продолжить аутентификацию',
  'Enter your OTP code with index {0}': 'Введите ваш OTP-код с индексом {0}',
  'Error during authentication:': 'Ошибка при аутентификации:',
  'Error while updating authentication status': 'Ошибка при обновлении статуса аутентификации',
  'Open the BankID app on this device': 'Открыть приложение BankID на этом устройстве',
  'OTP code': 'Одноразовый код',
  'Redirecting to your account servicing provider': 'Перенаправление к провайдеру, обслуживающему ваш счет',
  'Returning to the authentication initiator': 'Возвращение к инициатору аутентификации',
  'Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page': 'Запустите приложение BankID на вашем мобильном устройстве, нажмите кнопку QR-кода и отсканируйте QR-код с этой страницы.',
  'or': 'или',
  'Starting authorization': 'Запуск авторизации',
  'Start typing for filtering the list': 'Начните вводить для фильтрации списка',
  'To continue please use your authentication app': 'Чтобы продолжить, используйте приложение для аутентификации',
  'Waiting for completion of authentication': 'Ожидание завершения аутентификации',
  'Try again': 'Попробовать еще раз',
  'Submit': 'Отправить',
  'Unable to access server': 'Невозможно получить доступ к серверу',
  'Unable to authenticate': 'Невозможно аутентифицировать',
  'Unable to continue authorization': 'Невозможно продолжить авторизацию',
  'Unable to continue decoupled authorization': 'Невозможно продолжить несвязанную авторизацию',
  'Unable to handle authentication status "{status}"': 'Невозможно обработать статус аутентификации "{status}"',
  'Unknown error occurred': 'Произошла неизвестная ошибка',
  'You need to close this window': 'Вам нужно закрыть это окно',
  // Consent
  'Authentication is initiated by {0}.': 'Аутентификация инициирована {0}.',
  'After you complete authentication, {0} will be shared with the authentication initiator.': 'После завершения аутентификации {0} будут переданы инициатору аутентификации.',
  'your payment accounts data': 'данные ваших платежных счетов',
  'the list of your payment accounts': 'список ваших платежных счетов',
  'the list of your payment accounts and accounts balances': 'список ваших платежных счетов и остатки на счетах',
  'the list of your payment accounts and accounts transactions': 'список ваших платежных счетов и транзакции по счетам',
  'the list of your payment accounts, accounts balances and transactions': 'список ваших платежных счетов, остатки на счетах и транзакции',
  'By pressing {0} button you agree with the {1}.': 'Нажимая кнопку {0}, вы соглашаетесь с {1}.',
  'terms of Enable Banking API service': 'условиями использования Enable Banking API',
  'Press {0} button if you are not willing to share your payment accounts data.': 'Нажмите кнопку {0}, если вы не хотите предоставлять данные своих платежных счетов.',
  // Payment consent
  'Payment details': 'Детали платежа',
  'Payment ID provided by the application: {0}': 'Идентификатор платежа, предоставленный приложением: {0}',
  'Account number where to transfer funds: {0}': 'Номер счета для перевода средств: {0}',
  'Payee name in the payment: {0}': 'Имя получателя платежа: {0}',
  'Payee country: {0}': 'Страна получателя: {0}',
  'Payment amount: {0}': 'Сумма платежа: {0}',
  'Message or reference number in the payment: {0}': 'Сообщение или номер в платеже: {0}',
  'Payment is initiated by {0}.': 'Платеж инициирован {0}.',
  'After you complete authentication, your payment consent will be requested by your account servicing payment service provider.': 'После того, как вы завершите аутентификацию, ваше согласие на оплату будет запрошено поставщиком платежных услуг, обслуживающим вашу учетную запись.',
  'terms of service': 'условия использования',
  'Press {0} button if you are not willing to complete this payment.': 'Нажмите кнопку {0}, если вы не хотите совершать этот платеж.',
  'Select Payment Account': 'Выберите платежный счет',
  'Confirm': 'Подтвердить',
  // About Tilisy
  'Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.': 'Enable Banking API - это онлайн-сервис, который даёт вам возможность использовать данные ваших платежных счетов обслуживаемых выбранным банком или аналогичной организацией (т.н. поставщиком платежных услуг по обслуживанию счетов или ASPSP) в других цифровых сервисах.',
  'To use your payment account data, you must authenticate in the ASPSP service.': 'Чтобы использовать данные своего платежного счета, вы должны пройти аутентификацию в сервисе ASPSP.',
  'The service was formerly known as Tilisy business API.': 'Ранее сервис назывался Tilisy business API.',
  'Your explicit consent to use the payment account data will be maintained in Enable Banking API.': 'Ваше явное согласие на использование данных платежного счета будет сохранено в Enable Banking API.',
  'This service registers your PSU authentication tokens and consent ID.': 'Этот сервис регистрирует ваши токены аутентификации и идентификатор согласия.',
  'Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.': 'После того, как вы дали согласие в службе ASPSP, Enable Banking API открывает доступ к данным вашего платежного счета, чтобы цифровая служба, которую вы используете (т.е. инициатор аутентификации), могла получить эти данные.',
  'Your payment account data flows through Enable Banking API and won\'t be registered there.': 'Данные вашего платежного счета проходят через Enable Banking API но не сохраняются там.',
  'Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.': 'Enable Banking API принадлежит и управляется Enable Banking Oy, расположенным по адресу Otakaari 5, 02150 Эспоо, Финляндия, идентификатор компании 2988499-7.',
  'The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).': 'Компания является зарегистрированным поставщиком услуг (поставщик услуг по предоставлению информации о платёжных счетах, AISP) и находится под надзором Управления финансового надзора Финляндии (FIN-FSA).',
  'The registration details can be found from {0}.': 'Регистрационные данные можно найти в {0}.',
  'the FIN-FSA register for payment services providers': 'реестре поставщиков платежных услуг FIN-FSA',
  'Please read the {0} carefully before continuing to authentication.': 'Перед продолжением аутентификации внимательно прочтите {0}.',
  'You can check the terms and additional information on Enable Banking API service anytime from {0}.': 'Вы можете в любое время ознакомиться с условиями и дополнительной информацией о сервисе Enable Banking API на странице {0}.',
  'Information and updates on service or fraudulent or corresponding activity threatening the secure use of the service will be communicated to you by your digital service provider together with Enable Banking Oy.': 'Информация о сервисе, нововведения, а также информация о мошенничестве или другой деятельности, угрожающей безопасному использованию сервиса, будут сообщены вам вашим поставщиком цифровых услуг вместе с Enable Banking Oy.',
  // Terms of Service
  'Terms of Service': 'Условия использования',
  'Last updated:': 'Последнее обновление:',
  'Please read these terms of service carefully before using Enable Banking API.': 'Прежде чем использовать Enable Banking API, пожалуйста, внимательно прочтите эти условия обслуживания.',
  'Description of the service': 'Описание услуги',
  'Processing of personal data': 'Обработка персональных данных',
  'Enable Banking processes personal data only for the purpose and to the extent that Enable Banking API service can function for you.': 'Enable Banking обрабатывает персональные данные только для той цели и в той степени, в которой сервис Enable Banking API может работать для вас.',
  'When processing personal data Enable Banking API undertakes to comply with the legislation relating to processing of personal data and data protection as well as other legislation applicable and especially the EU General Data Protection Regulation (EU 2016/679).': 'При обработке персональных данных Enable Banking API обязуется соблюдать законодательство, касающееся обработки и защиты персональных данных, а также другие применимые законодательные акты, в частности, Общий регламент ЕС о защите данных (EU 2016/679).',
  'The more detailed contents of the personal data processing, including:': 'Более подробное содержание обработки персональных данных, в том числе:',
  '(a) the nature and purpose of the processing;': '(a) характер и цель обработки;',
  '(b) the type of personal data and categories of data subjects;': '(b) тип персональных данных и категории данных;',
  '(c) the applicable data protection measures;': '(c) применимые меры защиты данных;',
  '(d) the customer’s rights and responsibilities;': '(d) права и обязанности потребителя;',
  'and (e) the object and duration of the personal data processing shall be described in greater detail in the Privacy Policy.': 'и (e) объект и продолжительность обработки персональных данных более подробно описаны в Политике конфиденциальности.',
  'Links to other websites': 'Ссылки на другие сайты',
  'Enable Banking may contain links or redirect you to third-party web sites or services that are not owned or controlled by the Company, including but not limited to ASPSPs’ web sites or services.': 'Enable Banking может содержать ссылки или перенаправлять вас на сторонние веб-сайты или службы, которые не принадлежат или не контролируются Компанией, включая, помимо прочего, веб-сайты или сервисы ASPSP.',
  'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.': 'Компания не контролирует и не несет ответственности за содержание, политику конфиденциальности или действия любых сторонних веб-сайтов или сервисов.',
  'You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.': 'Вы признаете и соглашаетесь с тем, что Компания не несет ответственности, прямо или косвенно, за любой ущерб или убытки, вызванные или предположительно вызванные или связанные с использованием или доверием к любому такому контенту, товарам или услугам, доступных на или через любые такие веб-сайты или сервисы.',
  'We strongly advise you to read terms and conditions and privacy policies of any third-party web sites or services that you visit.': 'Мы настоятельно рекомендуем вам ознакомиться с условиями использования и политикой конфиденциальности любых сторонних веб-сайтов или сервисов, которые вы посещаете.',
  'No warranty': 'Отсутствие гарантии',
  'Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied:': 'Не ограничивая вышесказанное, ни Компания, ни какой-либо из ее поставщиков не делает никаких заявлений или гарантий любого рода, явных или подразумеваемых:',
  '(i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon;': '(i) относительно работы или доступности Сервиса или информации, контента и материалов или продуктов, включенных в него;',
  '(ii) that the Service will be uninterrupted or error-free;': '(ii) что Сервис будет бесперебойным или безошибочным;',
  '(iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.': '(iii) относительно точности, надежности или актуальности любой информации или контента, предоставляемого через Сервис.',
  'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.': 'В некоторых юрисдикциях не допускается исключение определенных типов гарантий или ограничений применимых законных прав потребителя, поэтому некоторые или все вышеперечисленные исключения и ограничения могут не относиться к вам.',
  'But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.': 'Но в таком случае исключения и ограничения, изложенные в этом разделе, применяются в максимальной степени, предусмотренной применимым законодательством.',
  'Changes': 'Изменения',
  'The Company reserves the right to modify or replace the Service at any time.': 'Компания оставляет за собой право изменять или заменять Сервис в любое время.',
  'If a revision is material the Company will make reasonable efforts to notify you prior to any new terms taking effect together with other digital service providers.': 'Если изменение является существенным, Компания приложит разумные усилия, чтобы уведомить вас до вступления в силу любых новых условий вместе с другими поставщиками цифровых услуг.',
  'You can also check any updates from this page.': 'Вы также можете проверить обновления на этой странице.',
  'By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms.': 'Продолжая получать доступ к Сервису или используя его после того, как эти изменения вступят в силу, вы соглашаетесь соблюдать пересмотренные условия.',
  'If you do not agree to the new terms, in whole or in part, please stop using the Service.': 'Если вы не согласны с новыми условиями полностью или частично, пожалуйста, прекратите использование Сервиса.',
  'Termination': 'Прекращение',
  'If for any reason, you no longer wish to use the Service to share your payment accounts data with other digital services, please {0}.': 'Если по какой-либо причине вы больше не хотите использовать Сервис для передачи данных своих платежных счетов другим цифровым службам, пожалуйста, {0}.',
  'visit the Data Sharing Consents page': 'посетите страницу "Согласия на Обмен Данными"',
  'Upon termination, your right to use the Service will cease immediately.': 'После уведомления о прекращении ваше право на использование Сервиса немедленно прекращается.',
  'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever.': 'Мы можем прекратить или приостановить ваш доступ по любой причине без предварительного уведомления или ответственности.',
  'Governing law': 'Применимое право',
  'The laws of Finland shall govern your use of the Service.': 'Использование вами Сервиса регулируется законами Финляндии.',
  'If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.': 'Если вы являетесь резидентом Европейского союза, то как потребитель вы попадаете под защиту всех обязательных положений законодательства соответствующей страны.',
  'Disputes resolution': 'Разрешение споров',
  'If you have any concern or dispute about the Service, at first, you are asked for trying to resolve the dispute informally by contacting the Company.': 'Если у вас есть какие-либо сомнения или возражения по поводу Сервиса, пожалуйста, сначала свяжитесь с Компанией и попытайтесь разрешить спор неформально.',
  'The consumer users in Finland are also entitled to be in contact with Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, kril@oikeus.fi.': 'Потребители являющиеся резидентами Финляндии также имеют право связываться с Советом по потребительским спорам, Hämeentie 3, P.O. Box 306, 00531 Helsinki, тел. +358 29 566 5200, kril@oikeus.fi.',
  'The company related issues shall be directed to the supervising authority.': 'Вопросы, связанные с компанией, следует направлять в надзорный орган.',
  'The FIN-FSA contact information is: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (switchboard); www.finanssivalvonta.fi.': 'Контактная информация FIN-FSA: Snellmaninkatu 6, 00100 Helsinki, Finland, тел. +358 9 183 51 (коммутатор); www.finanssivalvonta.fi.',
  'Contact us': 'Связь с нами',
  'If you have any questions about this terms of service, you can contact us by email at {0}.': 'Если у вас есть какие-либо вопросы об этих условиях использования, вы можете связаться с нами по электронной почте {0}.',
  // Backend messages
  '10 digit client number': '10-значный номер клиента',
  '10 digit organisation number (or 12 digit personal number if the company is a sole proprietorship)': '10-значный номер организации (или 12-значный личный номер, если компания является индивидуальным предпринимателем)',
  '10 digit organization number.': '10-значный номер организации.',
  '17 digit card number (starting with 6703)': '17-значный номер карты (начиная с 6703)',
  '6 digit Handelsbanken customer number': '6-значный номер клиента Handelsbanken',
  '8 digits CVR number': '8-значный номер CVR',
  '9 digits Organisasjonsnummer': '9-значный номер организации',
  'Agreement number': 'Номер договора',
  'Agreement number for Nordea Corporate Netbank': 'Номер договора для корпоративного интернет-банка Nordea',
  'Bank user ID': 'Идентификатор пользователя банка',
  'Business identity code (Y-tunnus) in 1234567-8 format': 'Код компании (Y-tunnus) в формате 1234567-8.',
  'Business identity code which is displayed in the Corporate Internet bank settings': 'Код компании, который отображается в настройках Корпоративного интернет-банка.',
  'Client ID': 'ID клиента',
  'Client number': 'Номер клиента',
  'Company ID': 'Идентификатор компании',
  'Company number': 'Номер компании',
  'Corporate ID': 'Корпоративный ID',
  'Corporate registration ID': 'Идентификатор регистрации компании',
  'Currency code': 'Код валюты',
  'Digit card number': 'Цифровой номер карты',
  'Disposer': 'Измельчитель',
  'E-mail': 'Электронная почта',
  'E-mail used for authenticating to Holvi': 'Электронная почта, используемая для аутентификации в Holvi',
  'Either the 14 digit SEB identifier for the company or the 10 digit organization number': 'Либо 14-значный идентификатор SEB компании, либо 10-значный номер организации.',
  'FKDN (without subaccount number): 3 digit branch number + 7 digit customer number': 'FKDN (без номера субсчета): 3-значный номер филиала + 7-значный номер клиента.',
  'For Swedish Corporates, this is their Organisation number or SHB number, and for Sole Traders (Enskild firma), it is their Personal number, all of which are 10 digits.': 'Для шведских корпораций это номер организации или номер SHB, а для индивидуальных предпринимателей (Enskild Firma) — это личный номер, каждый из которых состоит из 10 цифр.',
  'IBAN': 'IBAN',
  'ID, NIE, Passport': 'удостоверение личности, NIE, паспорт',
  'Login ID used in MyBRD aplications': 'Идентификатор входа, используемый в приложениях MyBRD',
  'Login Name': 'Логин',
  'Mandate ID': 'Идентификатор мандата',
  'Norwegian national identity number in the format DDMMYYXXXXX (11 digits)': 'Норвежский национальный идентификационный номер в формате DDMMYYXXXXX (11 цифр)',
  'Online banking PIN': 'ПИН-код онлайн-банкинга',
  'Online banking Password': 'Пароль онлайн-банкинга',
  'Organisation ID in the format TBxxxxx or TXxxxxx': 'Идентификатор организации в формате TBxxxxxx или TXxxxxxx.',
  'Organisation number': 'Номер организации',
  'PIN': 'ПИН',
  'Password': 'Пароль',
  'Password for Aktia Internet bank': 'Пароль для интернет-банка Aktia',
  'Password used for authenticating to Holvi': 'Пароль, используемый для аутентификации в Holvi',
  'Personal ID': 'Персональный идентификационный номер',
  'Personal ID number': 'Персональный идентификационный номер',
  'Personal code': 'Персональный код',
  'Personal code of the user': 'Персональный код пользователя',
  'Personal signatory number': 'Личный номер подписи',
  'Personal username for online banking': 'Персональное имя пользователя для онлайн-банкинга',
  'Postbank ID': 'Почтовый банковский идентификатор',
  'Social security number (Personnummer)': 'Номер социального страхования (Personnummer)',
  'Social security number in the format YYYYMMDDXXXX': 'Номер социального страхования в формате ГГГГММДДХХХХ.',
  'Swedish social security number in the format YYYYMMDDXXXX': 'Номер шведского социального страхования в формате ГГГГММДДХХХХ',
  'The IBAN of the user authenticating': 'IBAN пользователя, аутентифицирующего',
  'The currency code': 'Код валюты',
  'The user ID of the user authenticating': 'Идентификатор пользователя, аутентифицирующего пользователя',
  'Unique personal ID number': 'Уникальный личный идентификационный номер',
  'User ID': 'ID пользователя',
  'User ID for Aktia Internet bank': 'Идентификатор пользователя интернет-банка Aktia',
  'User ID for Nordea Corporate Netbank': 'Идентификатор пользователя корпоративного интернет-банка Nordea',
  'User ID for online banking': 'Идентификатор пользователя для онлайн-банкинга',
  'User ID in the format XX000000': 'Идентификатор пользователя в формате XX000000.',
  'User ID used to log in to the bank': 'Идентификатор пользователя, используемый для входа в банк',
  'Username': 'Имя пользователя',
  'Username used to log in to the bank': 'Имя пользователя, используемое для входа в банк',
  'VR NetKey': 'VR NetKey',
  'WebSSO user name': 'Имя пользователя WebSSO'
}
